import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TostrMessageService } from 'src/app/services/tostr-message.service';
import Swal from 'sweetalert2';
import { CategoryService } from '../category.service';
declare var $: any;

@Component({
  selector: 'app-sub-category-quote-add',
  templateUrl: './sub-category-quote-add.component.html',
  styleUrls: ['./sub-category-quote-add.component.css']
})
export class SubCategoryQuoteAddComponent implements OnInit {
  subCategoryQuoteCreate: any;
  isValid = false;
  editMode: any;
  value: any;
  previousImg: any;
  QuoteId: any;
  selectedFile: any;
  id: any;
  Data: any;
  selectedFileName: any;
  selectedFileShow: any;
  LanguageList: any = [];
  numitems: any;
  totalrecord: any;
  totalPages: any;
  currentpage: any;
  startdata: any;
  enddata: any;
  endtabledata: any;
  PageIndex: number = 1;
  count: number = 0;
  lanName: any;
  PageSize: number = 7;

  //for SubCategoryId 
  public SubCategoryID: any = localStorage.getItem('subcategoryid');
  public SUBCATEGORYID = JSON.parse(this.SubCategoryID);

  //for UserId Get

  public token: any = localStorage.getItem('token');
  public tokenConvert = JSON.parse(this.token);
  public UserId: any = this.tokenConvert.userid;
  SubCategoryId: any;

  constructor(private categoryservice: CategoryService, private _activatedRoute: ActivatedRoute, private router: Router, private _fb: FormBuilder, private tostrMessage: TostrMessageService, private quoteservice: CategoryService) {
    this.lanName = "";
    this.subCategoryQuoteCreate = this._fb.group({
      id: [''],
      title: ['', [Validators.required]],
      subcategoryid: [this.SUBCATEGORYID, [Validators.required]],
      quoteId: [''],
      ImageFile: [''],
      isActive: [true],
      language: ['', [Validators.required]]
    });
  }

  public error = (controlName: string, errorName: string) => {
    return this.subCategoryQuoteCreate.controls[controlName].hasError(errorName);
  }

  public chooseLanguage: any;

  GetlanguageLists(Language: any) {
    this.chooseLanguage = Language.target.value;
  }



  ngOnInit(): void {
    this.SubCategoryId = localStorage.getItem('SubCategoryID');
    this.GetlanguageList();
    $('.dropify').dropify({});
    $('#datepicker-popup').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
    });

    //  this.token= localStorage.getItem('token');
    //  let tokenConvert = JSON.parse(this.token)
    //  this.userId = tokenConvert.userid
    this.quoteservice.getQuotebyId(this._activatedRoute.snapshot.params['id'], this.UserId).subscribe((result: any) => {
      this.editMode = true;
      this.QuoteId = result.quoteId;
      this.previousImg = result.quoteImageUrl;
      this.chooseLanguage = result.language;
      this.subCategoryQuoteCreate = new FormGroup({
        Id: new FormControl(this.QuoteId),
        quoteId: new FormControl(this.QuoteId),
        title: new FormControl(result['title'], [Validators.required]),
        ImageFile: new FormControl(result['ImageFile'], [Validators.required]),
        SubcategoryID: new FormControl(this.SUBCATEGORYID),
        UserId: new FormControl(this.UserId),
        isActive: new FormControl(result['isActive']),
        language: new FormControl(result['language'], [Validators.required]),
      });
    });
  }

  backToSubCategoryQuoteList() {
    this.categoryservice.getSubcategoryWiseQuoteList(this.SubCategoryId, this.PageIndex, this.PageSize, this.lanName).subscribe((result) => {
      this.LanguageList = result;
      this.totalrecord = (this.LanguageList[0].totalrecord);

      this.numitems = this.totalrecord - 1;
      var startindex = 0;
      this.totalPages = Math.floor(this.numitems / this.PageSize);
      this.currentpage = Math.ceil(startindex / this.PageSize);

      this.startdata = ((this.PageIndex - 1) * this.PageSize) + 1;
      this.endtabledata = (this.startdata + this.PageSize) - 1;
      this.enddata = (this.endtabledata) > (this.totalrecord) ? (this.totalrecord) : (this.endtabledata);

    });
    this.router.navigate(['dashboard/sub-category-quote-list']);

  }

  onImageSubmit(event: any) {

    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = this.onSelectedFile(event);

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileShow = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubCategoryQuoteCreate() {
    this.isValid = true;
    // if (this.value != null || this.subCategoryQuoteCreate.value != null) {
    //   this.isValid = false;
    // }
    // else {
    //   this.isValid = true;
    //   return;
    // }
    this.subCategoryQuoteCreate.value.imageFile = this.selectedFile;
    var form_data = new FormData();
    if (this.subCategoryQuoteCreate.value.quoteId == 0 || this.subCategoryQuoteCreate.value.quoteId == null || this.subCategoryQuoteCreate.value.quoteId == '') {
      form_data.append("SubCategoryId", this.subCategoryQuoteCreate.value.subcategoryid);
      form_data.append("title", this.subCategoryQuoteCreate.value.title);
      form_data.append("UserId", this.UserId);
      form_data.append('ImageFile', this.selectedFile);
      form_data.append('language', this.chooseLanguage);
      form_data.append("isActive", this.subCategoryQuoteCreate.value.isActive);
      this.quoteservice.addQuote(form_data).subscribe((res: any) => {
        if (res.status == 'success') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.backToSubCategoryQuoteList();
        }
        else if (res.status == 'error') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
    }
    else {
      form_data.append("Id", this.subCategoryQuoteCreate.value.quoteId);
      form_data.append("Id", this.subCategoryQuoteCreate.value.Id);
      form_data.append("SubCategoryId", this.SUBCATEGORYID);
      form_data.append("title", this.subCategoryQuoteCreate.value.title);
      form_data.append("UserId", this.UserId);
      form_data.append('language', this.chooseLanguage);
      form_data.append("isActive", this.subCategoryQuoteCreate.value.isActive);
      if (this.selectedFile != null) {
        form_data.append('ImageFile', this.selectedFile);
        this.subCategoryQuoteCreate.value.ImageFile = this.selectedFile;
      } else {
        form_data.append('ImageFile', '');
        this.subCategoryQuoteCreate.value.ImageFile = '';
      }

      this.quoteservice.updateQuote(form_data).subscribe((res: any) => {
        if (res.status == 'success') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.backToSubCategoryQuoteList();
        }
        else if (res.status == 'error') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
    }
  }

  onSelectedFile(event: any) {
    let selectedFile = <File>event.target.files[0];
    let selectedFileName = selectedFile.name;
    return selectedFileName;
  }

  GetlanguageList() {
    this.quoteservice.GetLanguageList().subscribe(Language => {
      this.Data = Language;
    });
  }

}
