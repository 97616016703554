<div class="row">
    <div class="col-md-12 grid-margin ">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Version</h4>
                <p class="card-description"> </p>
                <form class="forms-sample" [formGroup]="appVersion">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="exampleInputUsername1">App Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="exampleInputUsername1" placeholder="AppName"
                                    formControlName="appName"><br>

                                <label for="exampleInputUsername1">Android App Version <span
                                        class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="exampleInputUsername1"
                                    placeholder="AndroidAppVersion" formControlName="androidAppVersion"><br>

                                <label for="exampleInputUsername1">Android Play StoreUrl <span
                                        class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="exampleInputUsername1"
                                    placeholder="AndroidAppStoreUrl" formControlName="playStoreUrl"><br>
                                    
                                <label for="exampleInputPassword1">Android Play Store Status</label>
                                <p>
                                    <mat-slide-toggle formControlName="playStoreStatus"> Active </mat-slide-toggle>
                                </p><br>

                                <label for="exampleInputUsername1">Ios App Version <span
                                        class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="exampleInputUsername1"
                                    placeholder="IosAppVersion" formControlName="iosAppVersion"><br>

                                <label for="exampleInputUsername1">Ios App StoreUrl <span
                                        class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="exampleInputUsername1"
                                    placeholder="IosPlayStoreUrl" formControlName="appStoreUrl"><br>

                                <label for="exampleInputPassword1">Ios App Store Status</label>
                                <p>
                                    <mat-slide-toggle formControlName="appStoreStatus"> Active </mat-slide-toggle>
                                </p><br>

                                <label for="exampleInputUsername1">Update Message <span
                                        class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="exampleInputUsername1"
                                    placeholder="Update Message" formControlName="updateMessage"><br>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-gradient-primary mr-2" (click)="udpateVersion()">
                        Update</button>
                </form>
            </div>
        </div>
    </div>
</div>