<div class="page-header">
    <h3 class="page-title">Sub Category</h3>

    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a (click)="navigateCategory()">Category</a></li>
            <li class="breadcrumb-item active">SubCategory</li>
        </ol>
    </nav>
</div>
<div class="card">
    <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
            <h4 class="card-title font-weight-bold">{{categoryName}}</h4>
            <button class="float-right add btn mb-3 btn-gradient-primary font-weight-bold todo-list-add-btn"
                (click)="createSubCategory()" id="add-task">Add</button>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <!-- <th>Order #</th> -->
                            <!-- <th>Image</th> -->
                            <th class="font-weight-bold">Name</th>
                            <th class="font-weight-bold">Image</th>
                            <!-- <th>Category</th>
                            <th>Status</th> -->
                            <th class="font-weight-bold">Actions</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of subcategorylist |paginate: {
                            itemsPerPage: PageSize,
                            currentPage: PageIndex,
                            totalItems: totalrecords
                            };
                        let i= index;">
                            <td>{{element.subCategoryName}}</td>
                            <td class="py-1">
                                <img *ngIf="element.imageName" src="{{element.imageName}}" width="30px" />
                                <img *ngIf="!element.imageName" src="assets/images/NullImage.png" width="30px" />
                            </td>
                            <!-- 
                            <td>Happy</td>
                            <td>Happy</td>
                            <td>
                                <label class="badge badge-success">Active</label>
                            </td> -->

                            <td class="">
                                <button class="btn btn-outline-primary btn-sm py-2"
                                    (click)="editSubCategory(element.subcategoryID)">
                                    <i title="Edit" class="fa-solid fa-pen-to-square"></i></button>
                                <span class="ml-2"></span>
                                <button class="btn btn-outline-danger  btn-sm py-2"
                                    (click)="deleteRow(element.subcategoryID)"><i title="Delete"
                                        class="fa-solid fa-trash"></i></button>
                            </td>
                            <td>
                                <button class="btn btn-gradient-primary btn-sm py-2"
                                    (click)="openSubCategoryQuote(element)">
                                    SubCategory Quote
                                </button>
                            </td>
                        </tr>
                        <!-- <tr>
                            <td>2</td>
                            <td class="py-1">
                                <img src="../../../assets/images/faces-clipart/pic-1.png" alt="image" />
                            </td>
                            <td>Happy</td>
                            <td>Happy</td>
                            <td>
                                <label class="badge badge-danger">De Active</label>
                            </td>
                            
                            <td class="">
                                <button class="btn btn-outline-primary btn-sm py-2 "><i
                                        class="fa-solid fa-pen-to-square"></i></button>
                                <span class="ml-2"></span>
                                <button class="btn btn-outline-danger  btn-sm py-2 "><i
                                        class="fa-solid fa-trash"></i></button>
                            </td>
                        </tr> -->


                    </tbody>
                </table>
                <div class="d-flex float-left mt-4 text-small">
                    Showing {{startdata}} to {{enddata}} of {{totalrecords}} Records
                </div>
                <div class="d-flex float-right mt-3">
                    <div class="mt-2">
                        Items per Page:
                        <select (change)="onTableSizeChange($event)">
                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                {{ size }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <pagination-controls previousLabel="Prev" nextLabel="Next"
                            (pageChange)="onTableDataChange($event)">
                        </pagination-controls>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>