import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TostrMessageService } from 'src/app/services/tostr-message.service';
import Swal from 'sweetalert2';
import { CategoryService } from '../category.service';
declare var $: any;

@Component({
  selector: 'app-sub-category-add',
  templateUrl: './sub-category-add.component.html',
  styleUrls: ['./sub-category-add.component.css']
})
export class SubCategoryAddComponent implements OnInit {
  isValid = false;
  previousImg: any;
  SubcategorybyId: any;
  public editMode: boolean = false;
  value: any;
  selectedFile: any;
  selectedFileName: any;
  selectedFileShow: any;
  public CategoryId: any;
  public CATEGORYID: any;

  constructor(private router: Router, private _fb: FormBuilder, private _activatedRoute: ActivatedRoute, private tostrMessage: TostrMessageService, private subcategoryservice: CategoryService, private _http: HttpClient) { }

  addSubCategory = this._fb.group({
    SubCategoryName: ['', [Validators.required]],
    CategoryID: [''],
    Image: [''],
    IsActive: [true]
  });

  public error = (controlName: string, errorName: string) => {
    return this.addSubCategory.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
    $('.dropify').dropify({});
    this.subcategoryservice.getSubCategorybyId(this._activatedRoute.snapshot.params['id']).subscribe((result: any) => {
      this.SubcategorybyId = result.subcategoryID;
      this.editMode = true;
      this.previousImg = result.imageName;
      this.addSubCategory = new FormGroup({
        CategoryID: new FormControl(result['CategoryId']),
        SubcategoryID: new FormControl(result['subcategoryId']),
        SubCategoryName: new FormControl(result['subCategoryName'], [Validators.required]),
        Image: new FormControl(result['image'], [Validators.required]),
        IsActive: new FormControl(result['isActive'])
      });
    });
  }

  Input(event: any) {

    if (event.target.value != null) {
      this.isValid = false;
    }
    this.value = event.target.value;
  }

  //Image event //
  onImageSubmit(event: any) {

    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = this.onSelectedFile(event);

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileShow = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubCategoryCreate() {
    this.isValid = true;
    let parseCategoryId: any = localStorage.getItem('categoryID');
    this.addSubCategory.value.CategoryID = parseCategoryId;
    const formdata = new FormData();
    formdata.append("CategoryID", this.addSubCategory.value.CategoryID);
    formdata.append("SubCategoryName", this.addSubCategory.value.SubCategoryName);
    formdata.append("IsActive", this.addSubCategory.value.IsActive);
    formdata.append('Image', this.selectedFile);
    this.subcategoryservice.addSubCategory(formdata, this.SubcategorybyId).subscribe((res: any) => {
      if (res.status == 'success') {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: res.message,
          showConfirmButton: false,
          timer: 1500,
        });
        this.backToSubCategoryList();
      }
      else if (res.status == 'Already Exist') {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: res.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  backToSubCategoryList() {
    this.CategoryId = localStorage.getItem('categoryID');
    this.router.navigate(['dashboard/sub-category', this.CategoryId]);
  }

  onSelectedFile(event: any) {
    let selectedFile = <File>event.target.files[0];
    let selectedFileName = selectedFile.name;
    return selectedFileName;
  }

}