import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.css']
})
export class CategoryAddComponent implements OnInit {
  category: any;
  categorybyId: any;
  isValid = false;
  value: any;
  public editMode: boolean = false;

  constructor(private _fb: FormBuilder, private router: Router, private _activatedRoute: ActivatedRoute, private categoryservice: CategoryService) {
  }
  addCategory = this._fb.group({
    CategoryName: ['', [Validators.required]],
    isActive: [true]
  });


  ngOnInit(): void {

    this.categoryservice.getCategorybyId(this._activatedRoute.snapshot.params['id']).subscribe((result: any) => {
      this.categorybyId = result;
      this.editMode = true;
      this.addCategory = new FormGroup({
        CategoryID: new FormControl(result['categoryID']),
        CategoryName: new FormControl(result['categoryName'], [Validators.required]),
        isActive: new FormControl(result['isActive'])
      });
    }
    );
  }

  backToCategoryList() {
    this.router.navigate(['dashboard/category']);
  }

  public error = (controlName: string, errorName: string) => {
    return this.category.controls[controlName].hasError(errorName);
  }

  Input(event: any) {

    if (event.target.value != null) {
      this.isValid = false;
    }
    this.value = event.target.value;
  }

  onCategoryCreate() {
    this.isValid = true;
    this.categoryservice.addCategory(this.addCategory.value).subscribe((res: any) => {
      if (res.status == 'success') {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: res.message,
          showConfirmButton: false,
          timer: 1500,
        });
        this.backToCategoryList();
      }
      else if (res.status == 'Already Exist') {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: res.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    })
  }

  Updatecategory() {
    this.categoryservice.updateCategory(this.addCategory.value).subscribe((category: any) => {
      if (category.status == 'success') {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: category.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.backToCategoryList();
    }
    );
  }
}
