import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UsersService } from '../users/users.service';
declare var $: any;
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  show: boolean = true;
  userName: any;
  email: any;
  role: any = localStorage.getItem('role');
  roleId: any = localStorage.getItem('roleId');
  PageIndex: number = 1;
  PageSize: number = 7;
  pageSizes = [7, 15, 25, 50, 100];
  totalRecords: any;
  numitems: any;
  totalPages: any;
  currentpage: any;
  startdata: any;
  enddata: any;
  endtabledata: any;
  menuList: any = [];
  userbyId: any;
  userId: any;
  showFiller = false;
  constructor(private router: Router, private navbarService: UsersService) {
    let token: any = localStorage.getItem('token');
    this.email = JSON.parse(window.atob(token.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    this.userId = localStorage.getItem('UserId');
  }

  ngOnInit() {
    if (this.roleId == "1") {
      this.router.navigate(['dashboard/category']);
    }
    else {
      this.router.navigate(['dashboard/category']);
    }
    this.Menu();
  }

  onLogOut() {
    Swal.fire({
      title: 'Are you sure to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('roleId');
        localStorage.removeItem('UserId');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('CategoryId');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('categoryID');
        localStorage.removeItem('subcategoryid');
        localStorage.removeItem('SubCategoryID');
        this.router.navigate(['']);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }


  Menu() {
    this.navbarService.GetModulewiseRolePermission(this.role).subscribe((result: any) => {
      this.menuList = result;
    });

    // this.numitems = this.totalRecords - 1;

    // var startindex = 0;
    // this.totalPages = Math.floor(this.numitems / this.PageSize);
    // this.currentpage = Math.ceil(startindex / this.PageSize);

    // this.startdata = ((this.PageIndex - 1) * this.PageSize) + 1;
    // this.endtabledata = (this.startdata + this.PageSize) - 1;
    // this.enddata = (this.endtabledata) > (this.totalRecords) ? (this.totalRecords) : (this.endtabledata);
  }

  editUser() {
    this.navbarService.getUserById(this.userId).subscribe((result: any) => {
      this.userbyId = result;
    });
    this.router.navigate(['dashboard/user-edits', this.userId]);
  }

}
