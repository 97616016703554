<div class="page-header">
    <h3 class="page-title"> Template Create</h3>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-behance"><a (click)="backToTemplateList()">Template List</a></li>
        <li class="breadcrumb-item active" aria-current="page">Template Create</li>
      </ol>
    </nav>
  </div>
  <div class="row">
  
    <div class="col-md-12 grid-margin ">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Template Create</h4>
          <p class="card-description"> </p>
          <form class="forms-sample" [formGroup]="templateMaster" (submit)="onTemplateCreate()" novalidate>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="exampleInputUsername1">Name</label>
                  <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Name"
                    formControlName="name">
                  <div class="mt-1">
                    <mat-error *ngIf="isValid && error('name','required')">Please enter name.</mat-error>
                  </div>
                </div>
              </div>
  
              <div class="col-6">
                <div class="form-group">
                    <label for="exampleInputUsername1">Template Position</label>
                    <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Template Position"
                      formControlName="templatePosition">
                    <div class="mt-1">
                      <mat-error *ngIf="isValid && error('templatePosition','required')">Please enter template position.</mat-error>
                    </div>
                  </div>
              </div>
            </div>
  
            <div class="row">
       

              <div class="col-6  ">
                <div class="w-75">
                  <label>Image </label>
                  <input type="file" class="dropify" formControlName="image" />
                </div>
                <div class="mt-1">
                  <mat-error *ngIf="isValid && error('image','required')">Please select image.</mat-error>
                </div>
              </div>
  
              <div class="col-6">
                <div class="form-group">
                    <label for="exampleInputUsername1">Name Position</label>
                    <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Name Position"
                      formControlName="namePosition">
                    <div class="mt-1">
                      <mat-error *ngIf="isValid && error('name','required')">Please enter name position.</mat-error>
                    </div>
                  </div>
           
                <div class="form-group">
                    <label for="exampleInputUsername1">Template Pattern</label>
                    <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Template pattern"
                      formControlName="templatePattern">
                    <div class="mt-1">
                      <mat-error *ngIf="isValid && error('templatePattern','required')">Please enter template Pattern.</mat-error>
                    </div>
                  </div>
              </div>

              
  
  
            </div>
  
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                    <p>
                        <mat-slide-toggle formControlName="isActive" >Status</mat-slide-toggle>
                    </p>
                    
                    <div class="mt-1">
                        <mat-error *ngIf="isValid && error('isActive','required')">Please enter status
                            </mat-error>
                    </div>
    
                </div>
            </div>
            </div>
  
      
            <div class="mt-3">
              <button type="submit" class="btn btn-gradient-primary mr-2">Submit</button>
              <button class="btn btn-light" (click)="backToTemplateList()">Cancel</button>
  
            </div>
          </form>
        </div>
      </div>
    </div>
  
  </div>
