import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  public jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(private router: Router, private http: HttpClient) { }

  async canActivate() {
    const token = localStorage.getItem("accessToken");
    var isRefreshSuccess
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }else{
       isRefreshSuccess = await this.refreshingTokens(token);
      if (!isRefreshSuccess) {
        this.router.navigate(["dashboard"]);
      }
    }


    return isRefreshSuccess;
  }
  private async refreshingTokens(token: string | null): Promise<boolean> {
    const refreshToken: string | null = localStorage.getItem("refreshToken");

    if (!token || !refreshToken) {
      return false;
    }

    const tokenModel = { accessToken: token, refreshToken: refreshToken };

    let isRefreshSuccess: boolean;
    try {
      const response = await lastValueFrom(this.http.post(environment.url + "/api/Account/RefreshToken", tokenModel));
      const newToken = (<any>response).accessToken;
      const newRefreshToken = (<any>response).refreshToken;
      localStorage.setItem("accessToken", newToken);
      localStorage.setItem("refreshToken", newRefreshToken);
      isRefreshSuccess = true;
      
    }
    catch(ex) {
      isRefreshSuccess = false;
    }
    return isRefreshSuccess;
  }
} 
