import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  displayedColumns: any[] = ['name', 'email', 'profileImage', 'bioData', 'action'];

  @ViewChild(MatSort)
  sort: MatSort = new MatSort;

  userlist: any = [];
  userbyId: any;

  PageIndex: number = 1;
  PageSize: number = 7;
  pageSizes = [7, 15, 25, 50, 100];
  totalRecords: any;
  numitems: any;
  totalPages: any;
  currentpage: any;
  startdata: any;
  enddata: any;
  endtabledata: any;
  userRole:any;
  constructor(private router: Router, private userservice: UsersService) { }

  ngOnInit() {
    this.userservice.getUserList(this.PageIndex, this.PageSize).subscribe((result: any) => {
      this.userlist = result;
      this.totalRecords = result.totalrecords;
    });
    this.numitems = this.totalRecords - 1;
    var startindex = 0;
    this.totalPages = Math.floor(this.numitems / this.PageSize);
    this.currentpage = Math.ceil(startindex / this.PageSize);
    this.startdata = ((this.PageIndex - 1) * this.PageSize) + 1;
    this.endtabledata = (this.startdata + this.PageSize) - 1;
    this.enddata = (this.endtabledata) > (this.totalRecords) ? (this.totalRecords) : (this.endtabledata);
  }

  deleteUser(userId: any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.userservice.deleteUser(userId).subscribe((result: any) => {
          if (result.status == "success") {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: result.message,
              showConfirmButton: false,
              timer: 1500, 
            });
            this.ngOnInit();
          }
          else if (result.status == "error") {
            Swal.fire({
              title: 'Cancelled',
              text: result.message,
              icon: 'error'
            });
          }

        });
      }
    });
  }

  createUser() {
    this.router.navigate(['dashboard/user-create']);
  }

  editUser(userId: any) {
    this.userservice.getUserById(userId).subscribe((result: any) => {
      this.userbyId = result;
    });
    this.router.navigate(['dashboard/user-edit', userId]);
  }

  onTableDataChange(event: any) {
    this.PageIndex = event;
    this.ngOnInit();
  }

  onTableSizeChange(event: any) {
    this.PageSize = Number(event.target.value);
    this.PageIndex = 1;
    this.ngOnInit();
  }

  sortByColumn(list: any[] | undefined, column: string, direction = 'desc'): any[] {
    let sortedArray = (list || []).sort((a, b) => {
      if (a[column] > b[column]) {
        return (direction === 'desc') ? 1 : -1;
      }
      if (a[column] < b[column]) {
        return (direction === 'desc') ? -1 : 1;
      }
      return 0;
    })
    return sortedArray;
  }
}
