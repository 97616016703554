import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-views-report',
  templateUrl: './views-report.component.html',
  styleUrls: ['./views-report.component.css']
})
export class ViewsReportComponent implements OnInit {

  constructor(private appService: AppService) { }

  list: any = [];
  totalrecords: any = 0;
  PageIndex: number = 1;
  PageSize: number = 7;
  numitems: any;
  totalPages: any = 0;
  currentpage: any;
  startdata: any = 0;
  endtabledata: any;
  enddata: any = 0;
  Reportdata: any = [];
  // pageSizes = [10,25, 50,80, 100];
  pageSizes = [7, 15, 25, 50, 75, 100];

  ngOnInit(): void {
    this.ReportDatas();
  }

  ReportDatas() {
    this.appService.ReportUserList(this.PageIndex, this.PageSize).subscribe((Report: any) => {
      this.Reportdata = Report;

      this.totalrecords = ((this.Reportdata[0].totalRecords) || 0);

      this.numitems = this.totalrecords - 1;

      var startindex = 0;
      this.totalPages = Math.floor(this.numitems / this.PageSize);
      this.currentpage = Math.ceil(startindex / this.PageSize);

      this.startdata = ((this.PageIndex - 1) * this.PageSize) + 1;
      this.endtabledata = (this.startdata + this.PageSize) - 1;
      this.enddata = (this.endtabledata) > (this.totalrecords) ? (this.totalrecords) : (this.endtabledata);
    });
  }

  onTableDataChange(event: any) {
    this.PageIndex = event;
    this.ngOnInit();
  }
  onTableSizeChange(event: any): void {
    this.PageSize = Number(event.target.value);
    this.PageIndex = 1;
    this.ngOnInit();
  }
}
