<div class="page-header">
  <h3 class="page-title"> User {{ editMode ? "Update" : "Creation" }} </h3>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a (click)="backToUserList()">Users List</a></li>
      <li class="breadcrumb-item active" aria-current="page">User Create</li>
    </ol>
  </nav>
</div>
<div class="row">
  <div class="col-md-12 grid-margin ">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ editMode ? "Edit" : "Create" }} User</h4>
        <p class="card-description"> </p>
        <form class="forms-sample" [formGroup]="userCreate">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="exampleInputUsername1">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Name"
                  formControlName="name">
                <div class="mt-1" *ngIf="isValid && userCreate.controls['name'].invalid">
                  <div class="text-small text-danger">
                    Please enter name.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="exampleInputPassword1">Phone Number <span class="text-danger">*</span></label>
                <input type="number" class="form-control" id="exampleInputPassword1" placeholder="Phone Number"
                  (keydown)="Input($event)" formControlName="phoneNumber"
                  onKeyPress="if(this.value.length==10) return false;">
                <div class="mt-1" *ngIf="isValid && userCreate.controls['phoneNumber'].invalid">
                  <div class="text-small text-danger">
                    Please enter phone number.
                  </div>
                  <div class="text-small text-danger"
                    *ngIf="userCreate.controls['phoneNumber'].errors?.['confirmPasswordValidator']">
                    New Passsword and Confirm Password didn't match. </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="exampleInputEmail1">Email <span class="text-danger">*</span></label>
                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Email"
                  formControlName="email" pattern="^\S*[@]\S*[.]\S*$" [readOnly]="editMode">
                <div class="mt-1" *ngIf="isValid && userCreate.controls['email'].invalid">
                  <div class="text-small text-danger">
                    Please enter email.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="exampleInputPassword1">Password <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Ex:- John@123"
                  formControlName="password" [readOnly]="editMode" >
                <div class="mt-1" *ngIf="isValid && userCreate.controls['password'].invalid ">
                  <div class="text-small text-danger">
                    Please enter password.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!userRole">
            <div class="col-6">
              <div class="form-group">
                <label class="exampleInputPassword1">Role <span class="text-danger">*</span></label>
                <select class="form-control" formControlName="role" placeholder="Role" (change)="setRole($event)">
                  <option value="SuperAdmin">Super Admin</option>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                </select>
                <div class="mt-1" *ngIf="isValid && userCreate.controls['role'].invalid ">
                  <div class="text-small text-danger">
                    Please select role.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!userRole">
            <div class="col-6">
              <div class="form-group mt-1">
                <label for="exampleInputPassword1">Status</label>
                <p>
                  <mat-slide-toggle formControlName="isActive"> Active </mat-slide-toggle>
                </p>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-gradient-primary mr-2" (click)="onUserCreate()">Submit</button>
          <button class="btn btn-light" (click)="backToUserList()">Cancel</button>
        </form>
      </div>
    </div>
  </div>
</div>