import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  isValid = false;
  userbyId: any;
  public editMode: boolean = false;
  email: any;
  userId: any;
  setUserRole: any;
  role: any = localStorage.getItem('roleId');
  userRole: any;

  constructor(private router: Router, private _fb: FormBuilder, private userservice: UsersService, private _activatedRoute: ActivatedRoute) { }

  invalidChars = [
    "-",
    "+",
    "e",
    "."
  ];

  userCreate = this._fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required], [Validators.email]],
    phoneNumber: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    password: ['', [Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}")]],
    role: ['', [Validators.required]],
    isActive: ['']
  });

  ngOnInit() {
    this.userCreate = new FormGroup({
      name: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      role: new FormControl('', [Validators.required]),
      isActive: new FormControl('')
    });
    
    if (this._activatedRoute.snapshot.params['id'] != null) {
      this.getUserById();
    }
  }

  getUserById() {
    this.userservice.getUserById(this._activatedRoute.snapshot.params['id']).subscribe((result: any) => {
      this.userbyId = result;
      this.email = result.email;
      this.userId = result.userId;

      if (result.role == "SuperAdmin") {
        this.userRole = "SuperAdmin";
      }
      if (this.role == 2) {
        this.userRole = "Admin";
      }
      if (result.userId != null) {
        this.editMode = true;
      }

      this.userCreate = new FormGroup({
        name: new FormControl(result['name'], [Validators.required]),
        phoneNumber: new FormControl(result['phoneNumber'], [Validators.required]),
        password: new FormControl(''),
        email: new FormControl(result['email'], [Validators.required]),
        role: new FormControl(result['role'], [Validators.required]),
        isActive: new FormControl(result['isActive'])
      });
    });
  }


  backToUserList() {
    if (this.role == 2) {
      this.router.navigate(['dashboard/category']);
    }
    else {
      this.router.navigate(['dashboard/user-list']);
    }
  }

  onUserCreate() {
    this.isValid = true;
    this.userCreate.value.id = this.userId;
    this.userCreate.value.phoneNumber = String(this.userCreate.value.phoneNumber);
    if (this.userCreate.invalid) {
      return;
    }
    else {
      if (this.userCreate.value.id == 0 || this.userCreate.value.id == undefined || this.userCreate.value.id == null) {
        var addUser = {
          name: this.userCreate.value.name,
          phoneNumber: this.userCreate.value.phoneNumber,
          password: this.userCreate.value.password,
          email: this.userCreate.value.email,
          role: this.setUserRole,
          isActive: this.userCreate.value.isActive,
        }

        this.userservice.addUser(addUser).subscribe((res: any) => {
          if (res.status == 'success') {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 1500,
            });
            this.backToUserList();
          } else {
            if (res.status == 'error') {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: res.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        });
      }
      else if (this.userCreate.value.id != null) {
        this.userservice.addUser(this.userCreate.value).subscribe((res: any) => {
          this.userCreate.value.email = this.email;
          this.userCreate.value.role = this.setUserRole;
          if (res.status == 'success') {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 1500,
            });
            this.backToUserList();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: res.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    }
  }

  public error = (control: string, error: string) => {
    return this.userCreate.controls[control].hasError(error);
  }

  Input(e: any) {
    if (this.invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }

  setRole(role: any) {
    this.setUserRole = role.target.value;
  }
}