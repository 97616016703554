import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TostrMessageService } from 'src/app/services/tostr-message.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userProfile:any;
  isValid = false;
  constructor( private _fb: FormBuilder, private toastr:TostrMessageService) {
    this.userProfile = this._fb.group({
      email: ['', [Validators.required], [Validators.email]],
      name: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      password: ['', [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}")]],
      role: ['', Validators.required],
    });
  }

  public error = (controlName: string, errorName: string) => {
    return this.userProfile.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
  }

  onUserProfileUpdate() {
    this.isValid = true;
    if (this.userProfile.invalid) {
      return;
    }
    else {
      this.toastr.showSuccess("Profile Updated successfully.")
    }
  }

}
