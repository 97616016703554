<div class="page-header">
  <h3 class="page-title">
    <span class="page-title-icon bg-gradient-primary text-white mr-2">
      <i class="fa-solid fa-house"></i>
    </span> Dashboard
  </h3>
  <nav aria-label="breadcrumb">
    <ul class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">
        <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
      </li>
    </ul>
  </nav>
</div>
<div class="row">
  <div class="col-md-4 stretch-card grid-margin">
    <div class="card bg-gradient-danger card-img-holder text-white">
      <div class="card-body" (click)="backToUser()">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">UserCounts <i class="fa-solid fa-chart-line float-right mdi-24px"></i>
        </h4>
        <h2 class="mb-5">{{Counts.userCount}}</h2>
        <h6 class="card-text">Increased by 60%</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin">
    <div class="card bg-gradient-info card-img-holder text-white">
      <div class="card-body" (click)="backToCategory()">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Sub-Category<i class="fa-regular fa-bookmark mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">{{Counts.subCategoryCount}}</h2>
        <h6 class="card-text">Decreased by 10%</h6>
      </div>
    </div>
  </div>
  <div class="col-md-4 stretch-card grid-margin">
    <div class="card bg-gradient-success card-img-holder text-white">
      <div class="card-body">
        <img src="assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
        <h4 class="font-weight-normal mb-3">Quotes<i
            class="fa-solid fa-gem mdi-24px float-right mdi-24px float-right"></i>
        </h4>
        <h2 class="mb-5">{{Counts.quoteCount}}</h2>
        <h6 class="card-text">Increased by 5%</h6>
      </div>
    </div>
  </div>
</div>
<!-- <div class="row">
    <div class="col-md-7 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="clearfix">
            <h4 class="card-title float-left">Visit And Sales Statistics</h4>
            <div id="visit-sale-chart-legend" class="rounded-legend legend-horizontal legend-top-right float-right"></div>
          </div>

          <canvas id="visit-sale-chart" class="mt-4"></canvas>
        </div>
      </div>
    </div>
    <div class="col-md-5 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Traffic Sources</h4>
          <canvas id="traffic-chart"></canvas>
          <div id="traffic-chart-legend" class="rounded-legend legend-vertical legend-bottom-left pt-4"></div>
        </div>
      </div>
    </div>
  </div> -->
<div class="row">
  <div class="col-6 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-danger">Recent Users</h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th><b>UserName</b></th>
                <th><b>Email</b></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let userItem of RecentUser">
                <td>{{userItem.name}}</td>
                <td>{{userItem.email}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-6 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-danger">Most View Quotes</h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th><b>Image</b></th>
                <th><b>Count</b></th>
                <th><b>Category/Subcategory</b></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let view of View">
                <td>
                  <img src="{{view.image}}">
                  <img *ngIf="!view.image" src="assets/images/NullImage.png" width="30px" />
                </td>
                <td>{{view.quoteCount}}</td>
                <td>{{view.category}}/{{view.subcategory}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-6 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-danger">Most Like Quotes</h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th><b>Image</b></th>
                <th><b>Count</b></th>
                <th><b>Category/Subcategory</b></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let like of Like">
                <td><img src="{{like.image}}">
                  <img *ngIf="!like.image" src="assets/images/NullImage.png" width="30px" /></td>
                <td>{{like.quoteCount}}</td>
                <td>{{like.category}}/{{like.subcategory}}</td>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-danger">Most Downloaded Quotes</h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th><b>Image</b></th>
                <th><b>Count</b></th>
                <th><b>Category/Subcategory</b></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let download of Download">
                <td><img src="{{download.image}}">
                  <img *ngIf="!download.image" src="assets/images/NullImage.png" width="30px" /></td>
                <td>{{download.quoteCount}}</td>
                <td>{{download.category}}/{{download.subcategory}}</td>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>