import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AppService {
  public url = environment.url + 'api/Account/';
  public CountAllList = environment.url + 'api/Quote/GetDashbordCountList';
  login = 'AdminLogin';
  changepassword = 'changePassword';
  resetPassword = 'resetPassword';
  RecentUserList = 'GetRecentUserList';
  public Download = environment.url + 'api/Account/GetTopQuoteList?type=Download';
  public Like = environment.url + 'api/Account/GetTopQuoteList?type=Like';
  public View = environment.url + 'api/Account/GetTopQuoteList?type=View';
  public Reportlist = environment.url + 'api/Quote/GetUserUploadQuoteList';
  public updateversion = environment.url + 'api/AppVersion/updateversion';
  public VersionById = environment.url + 'api/AppVersion/getupdateversionbyid?id=2';

  constructor(private _http: HttpClient) { }

  postLoginList(data: any) {
    return this._http.post(this.url + this.login, data);
  }

  postChangePassword(data: any) {
    return this._http.post(this.url + this.changepassword, data);
  }

  postresetPassword(data: any) {
    return this._http.post(this.url + this.resetPassword, data);
  }

  appUpdateversion(data: any) {
    return this._http.put(this.updateversion, data);
  }

  TopUserList() {
    return this._http.get(this.url + this.RecentUserList)
  }

  CountLists() {
    return this._http.get(this.CountAllList);
  }

  Downloads() {
    return this._http.get(this.Download);
  }

  Likes() {
    return this._http.get(this.Like);
  }

  Views() {
    return this._http.get(this.View);
  }

  ReportUserList(PageIndex: any, PageSize: any) {
    return this._http.get(`${this.Reportlist + "?PageIndex="}${PageIndex}&PageSize=${PageSize}`);
  }

  appVersionById() {
    return this._http.get(this.VersionById);
  }
}
