import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TostrMessageService } from 'src/app/services/tostr-message.service';
declare var $: any;

@Component({
  selector: 'app-teplate-master',
  templateUrl: './teplate-master.component.html',
  styleUrls: ['./teplate-master.component.css']
})
export class TeplateMasterComponent implements OnInit {
  templateMaster: any;
  isValid = false;

  
  constructor(private router:Router ,private _fb: FormBuilder ,private tostrMessage:TostrMessageService) {
    this.templateMaster = this._fb.group({
      name: ['', [Validators.required]],
      templatePosition: ['', [Validators.required]],
      templatePattern: ['', [Validators.required]],
      namePosition: ['', [Validators.required]],
      image: ['', [Validators.required]],
      isActive: [true, [Validators.required]],
    })
   }
  
  public error = (controlName: string, errorName: string) => {
    return this.templateMaster.controls[controlName].hasError(errorName);
  }
  ngOnInit(): void {
    $('.dropify').dropify({}); 
  }

  backToTemplateList() {
    this.router.navigate(['dashboard/template-master'])
  }

  onTemplateCreate() {
    this.isValid = true;
    if(this.templateMaster.invalid) {
      return
    }
    else {
      this.tostrMessage.showSuccess("Template created successfully.");
      this.backToTemplateList();

    }
  }
}
