import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
// import * as XLSX from 'xlsx';
import { CategoryService } from '../category.service';
import { ThemeService } from 'ng2-charts';

@Component({
  selector: 'app-sub-category-quote-list',
  templateUrl: './sub-category-quote-list.component.html',
  styleUrls: ['./sub-category-quote-list.component.css']
})

export class SubCategoryQuoteListComponent implements OnInit {

  // @ViewChild('TABLE', { static: false }) TABLE: ElementRef | undefined ;
  // ExportTOExcel() {  
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE?.nativeElement);  
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();  
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');  
  //   XLSX.writeFile(wb, 'ScoreSheet.xlsx');
  // }

  public token: any = localStorage.getItem('token');
  public tokenConvert = JSON.parse(this.token);
  public userId: any = this.tokenConvert.userid;
  show: boolean = true;
  // title = 'Excel';
  SubCategoryId: any;
  SubCategoryID: any;
  file: any;
  quoteId: any;
  totalrecord: any = 10;
  numitems: any;
  totalPages: any;
  currentpage: any;
  startdata: any = 0;
  enddata: any = 0;
  endtabledata: any;
  langugeId: any = "";
  public CategoryId: any = localStorage.getItem('categoryID');
  displayedColumns: any[] = ['id', 'title', 'image', 'action'];

  PageIndex: number = 1;
  count: number = 0;
  PageSize: number = 7;
  lanPageSize: number = 2;
  pageSizes = [7, 15, 25, 50, 100];
  LanguageList: any = [];
  lanName: any;
  result: any;
  subCategoryName: any
  constructor(private router: Router, private _activatedRoute: ActivatedRoute, private categoryservice: CategoryService) {
    this.LanguageList = [];
    this.lanName = "";
  }

  ngOnInit() {
    this.SubCategoryId = localStorage.getItem('SubCategoryID');

    this.categoryservice.getSubCategorybyId(this.SubCategoryId).subscribe((result: any) => {
      this.result = result;
      this.subCategoryName = this.result.subCategoryName;
    });

    this.GetQuoteLanguageList();

  }

  navigateCategory() {  
    this.router.navigate(['/dashboard/category']);
  }
  backToSubCategoryList() {

    this.CategoryId = localStorage.getItem('CategoryId');
    this.router.navigate(['dashboard/sub-category', JSON.parse(this.CategoryId)]);
  }

  createSubCategoryQuote() {
    this.router.navigate(['dashboard/sub-category-quote-create']);
  }

  selectLanguage(Language: any) {
    this.lanName = Language.target.value;
    this.PageIndex = 1;
    this.GetQuoteLanguageList();
  }

  // onFileChange(event: any) {
  //   this.file = <File>event.target.files[0]
  //   this.show = false

  // }

  // onImportExcelSubmit() {
  //   
  //   const formData = new FormData();
  //   formData.append('postedFile', this.file);
  //   this.categoryservice.importExcel(formData).subscribe((res: any) => {
  //     this.ngOnInit();
  //   });
  // }

  deleteRow(item: any) {

    Swal.fire({
      title: 'Are you sure?',
      //text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.categoryservice.deleteQuotes(item, this.userId).subscribe((result: any) => {
          this.ngOnInit();
          if (result.status == "success") {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Deleted!',
              text: result.message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (result.status == "error") {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Cancelled',
              text: result.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }

        });
      }
    });

  }

  editQuote(quoteID: any) {

    this.categoryservice.getQuotebyId(quoteID, this.userId).subscribe((result: any) => {
      this.quoteId = result;
    });
    this.router.navigate(['dashboard/sub-category-quote-edit', quoteID]);
  }
  onTableDataChange(event: any) {
    this.PageIndex = event;
    this.ngOnInit();
  }
  onTableSizeChange(event: any): void {
    this.PageSize = Number(event.target.value);
    this.PageIndex = 1;
    this.ngOnInit();
  }

  GetQuoteLanguageList() {
    this.categoryservice.getSubcategoryWiseQuoteList(this.SubCategoryId, this.PageIndex, this.PageSize, this.lanName).subscribe((result) => {
      this.LanguageList = result;

      if (this.LanguageList == null || []) {
        this.totalrecord = "";
        this.totalrecord = "";

        this.numitems = "";
        var startindex = 0;
        this.totalPages = "";
        this.currentpage = "";

        this.startdata = 0;
        this.endtabledata = ""
        this.enddata = 0;
      }

      this.totalrecord = (this.LanguageList[0].totalrecord);

      this.numitems = this.totalrecord - 1;
      var startindex = 0;
      this.totalPages = Math.floor(this.numitems / this.PageSize);
      this.currentpage = Math.ceil(startindex / this.PageSize);

      this.startdata = ((this.PageIndex - 1) * this.PageSize) + 1;
      this.endtabledata = (this.startdata + this.PageSize) - 1;
      this.enddata = (this.endtabledata) > (this.totalrecord) ? (this.totalrecord) : (this.endtabledata);

    });


    this.categoryservice.getCategorywiseSubcategoryList(this.CategoryId, this.PageIndex, this.PageSize).subscribe((result) => {
      this.result = result;
    });
  }

}