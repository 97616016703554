import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
  public token: any = localStorage.getItem('token');
  public tokenConvert = JSON.parse(this.token);
  public userId: any = this.tokenConvert.userid;

  public ImageUrl = environment.url + "content/Images/";
  public categoryurl = environment.url + 'api/Category/';
  public subcategoryurl = environment.url + 'api/SubCategory/';
  public quotesurl = environment.url + 'api/Quote/';
  public languageurl = environment.url + 'api/GroupMas/';
  public quoteLanguageurl = environment.url + 'api/Quote/';
  public importExcelSubcategory = environment.url + 'api/Quote/';
  public importZipSubcategory = environment.url + 'api/Quote/';

  //Category
  AddCategory = 'CreateCategory';
  updateCategoryApi = 'UpdateCategory';
  GetCategoryList = 'getcategorylist';
  GetCategorybyId = 'getcategorybyid';
  DeleteCategory = 'deletecategory';
  //SubCategory
  GetCategorywiseSubcategoryList = 'getsubcategorylistbycategoryid';
  AddSubCategory = 'CreateSubCategory';
  UpdateSubCategoryApi = 'UpdateSubCategory';
  GetSubCategorybyId = 'getsubcategorybyid';
  DeleteSubCategory = 'deletesubcategory';
  //Quotes
  GetQuotesbySubCategoryId = 'GetQuoteListBySubCategoryId';
  AddQuotes = 'CreateQuoteUpload';
  GetQuoteById = 'Admin/GetQuoteById';
  DeleteQuotesbyId = 'DeleteQuoteByUserId';
  UpdateQuote = 'UpdateQuote';
  //GetLanguagesList
  LanguagesList = 'GetLaguageList';
  quoteLanguagesList = 'GetQuoteLangugeList';

  constructor(private _http: HttpClient) { }
  //Category
  addCategory(data: any) {
    const addCategory = this.categoryurl + this.AddCategory
    return this._http.post(addCategory, data);
  }
  updateCategory(data: any) {
    return this._http.put(this.categoryurl + this.updateCategoryApi, data);
  }
  getCategoryList(PageIndex: any, PageSize: any) {
    return this._http.get(`${this.categoryurl + this.GetCategoryList + "?PageIndex="}${PageIndex}&PageSize=${PageSize}&devicetype=${'website'}`);
  }
  getCategorybyId(categoryID: any) {
    return this._http.get(`${this.categoryurl + this.GetCategorybyId + "?id="}${categoryID}`);
  }
  deleteCategory(id: any) {
    return this._http.delete(`${this.categoryurl + this.DeleteCategory + "?id="}${id}`);
  }
  //SubCategory
  getCategorywiseSubcategoryList(categoryID: any, PageIndex: any, PageSize: any) {
    return this._http.get(`${this.subcategoryurl + this.GetCategorywiseSubcategoryList + "?categoryId="}${categoryID}&PageIndex=${PageIndex}&PageSize=${PageSize}&devicetype=${'website'}`);
  }
  addSubCategory(data: any, subCategoryId: any) {
    let addSubCategories = this.subcategoryurl + this.AddSubCategory
    if (subCategoryId != null) {
      data.append("SubcategoryID", subCategoryId);
      addSubCategories = this.subcategoryurl + this.UpdateSubCategoryApi
      return this._http.put(addSubCategories, data);
    }
    else {
      return this._http.post(addSubCategories, data);
    }
  }

  // makeUploadRequest(body: any) {
  //   const token1 = localStorage.getItem("accessToken");
  //   const headers = new HttpHeaders({
  //     'Authorization': 'Bearer ' + token1,
  //     'Accept-Language': 'en-US',
  //     'Content-Type': 'multipart/form-data'
  //   });
  //   const url = this.subcategoryurl + this.AddSubCategory
  //   let subCategoryViewModel = body;
  //   return this._http.post(url, subCategoryViewModel, { headers: headers });
  // }

  getSubCategorybyId(subCategoryID: any) {
    return this._http.get(`${this.subcategoryurl + this.GetSubCategorybyId + "?id="}${subCategoryID}`);
  }

  UpdateSubCategory(data: any) {
    return this._http.put(this.subcategoryurl + this.UpdateSubCategoryApi, data)
  }

  deleteSubCategory(id: any) {
    return this._http.delete(`${this.subcategoryurl + this.DeleteSubCategory + "?id="}${id}`);
  }
  //Quotes
  getSubcategoryWiseQuoteList(subCategoryId: any, PageIndex: any, PageSize: any, langugeName: any) {
    return this._http.get(`${this.quotesurl + this.GetQuotesbySubCategoryId + "?PageIndex="}${PageIndex}&PageSize=${PageSize}&subCategoryId=${subCategoryId}&langugeName=${langugeName}`);
  }
  addQuote(data: any) {
    const addQuotes = this.quotesurl + this.AddQuotes
    return this._http.post(addQuotes, data);
  }
  getQuotebyId(id: any, userId: any) {
    return this._http.get(`${this.quotesurl + this.GetQuoteById + "?id="}${id}&UserId=${userId}`);
  }
  deleteQuotes(id: any, userId: any) {
    return this._http.delete(`${this.quotesurl + this.DeleteQuotesbyId + "?quoteId="}${id}&userId=${userId}&devicetype=${'website'}`);
  }
  updateQuote(data: any) {
    const updateQuotes = this.quotesurl + this.UpdateQuote
    return this._http.put(updateQuotes, data);
  }
  GetLanguageList() {
    return this._http.get(`${this.languageurl + this.LanguagesList + "?id="}`);
  }

  GetQuoteLanguageList(PageIndex: any, PageSize: any, langugeId: any) {
    return this._http.get(`${this.quoteLanguageurl + this.quoteLanguagesList + "?PageIndex="}${PageIndex}&PageSize=${PageSize}&langugeId=${langugeId}`);
  }

  importExcel(data: any) {
    return this._http.post(this.importExcelSubcategory + "ImportImageExcel?UserId=" + this.userId, data);
  }

  importZip(data: any) {
    return this._http.post(this.importZipSubcategory + "ImportImageZip", data);
  }

}
