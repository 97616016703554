import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../category/category.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-importquote',
  templateUrl: './importquote.component.html',
  styleUrls: ['./importquote.component.css']
})
export class ImportquoteComponent implements OnInit {

  constructor(private categoryService: CategoryService) { }

  show: boolean = false;
  shown: boolean = false;
  file: any;
  isLoadingExcel: boolean = false;
  isLoadingZip: boolean = false;
  selectZip: any;
  selectExcel: any;

  ngOnInit() {
  }

  onExcelFileChange(event: any) {
    this.file = <File>event.target.files[0]
    this.show = true;
  }

  onImportExcel() {
    this.isLoadingExcel = true;
    const formData = new FormData();
    formData.append('postedFile', this.file);
    this.categoryService.importExcel(formData).subscribe((res: any) => {
      if (res.status == 'success') {
        this.isLoadingExcel = false;
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: res.message,
          confirmButtonText: 'OK',
          timer: 5000
        }).then((res) => {
          if (res.isConfirmed) {
          }
        })
        this.selectExcel = "";
        this.show = false;
      }
      else {
        this.isLoadingExcel = false;
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Please Upload Excel File...',
          confirmButtonText: 'OK',
          timer: 5000
        })
        this.selectExcel = "";
        this.show = false;
      }
    });
  }

  onZipFileChanges(event: any) {
    this.file = <File>event.target.files[0];
    this.shown = true;
  }

  onImportZip() {
    this.isLoadingZip = true;
    const formData = new FormData();
    formData.append('postedFile', this.file);
    this.categoryService.importZip(formData).subscribe((res: any) => {
      if (res.status == 'success') {
        this.isLoadingZip = false;
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: res.message,
          confirmButtonText: 'OK',
          timer: 5000
        }).then((res) => {
          if (res.isConfirmed) {
          }
        })
        this.selectZip = "";
        this.shown = false;
      }
      else {
        this.isLoadingZip = false;
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: res.message,
          confirmButtonText: 'OK',
          timer: 5000
        })
        this.selectZip = "";
        this.shown = false;
      }
    });
  }

}
