import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  chartOption: EChartsOption = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
      },
    ],
  };
  echartsInstance: any;
  RecentUser: any;
  Type: any;
  View: any;
  Like: any;
  Download: any;
  Counts: any;
  userCount: any;

  constructor(private router: Router, private appService: AppService) { }

  ngOnInit() {
    this.UserList();
    this.CountList();
    this.ForDownloads();
    this.ForLikes();
    this.ForViews();
  }

  onChartInit(ec: any) {
    this.echartsInstance = ec;
  }

  resizeChart() {
    if (this.echartsInstance) {
      this.echartsInstance.resize();
    }
  }

  backToUser() {
    this.router.navigate(['dashboard/user-list'])
  }

  backToCategory() {
    this.router.navigate(['dashboard/category'])
  }

  UserList() {
    this.appService.TopUserList().subscribe(User => {
      this.RecentUser = User;
    });
  }

  CountList() {
    this.appService.CountLists().subscribe(Count => {
      this.Counts = Count
      // let a = this.Counts.userCount
      // let b = this.Counts.subCategoryCount
      // let c = this.Counts.quoteCount
      // console.log(a,b,c,'abc');
      // console.log(this.Counts,"Counts");
      // this.userCount = this.Counts.userCount
    });
  }

  ForDownloads() {
    this.appService.Downloads().subscribe(Downloads => this.Download = Downloads);
  }

  ForLikes() {
    this.appService.Likes().subscribe(Likes => this.Like = Likes);
  }

  ForViews() {
    this.appService.Views().subscribe(Views => this.View = Views);
  }

}
