<div class="page-header">
  <h3 class="page-title"> Users </h3>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <!-- <li class="breadcrumb-item"><a href="#">Users</a></li>
          <li class="breadcrumb-item active" aria-current="page">Data table</li> -->
    </ol>
  </nav>
</div>
<div class="card">
  <div class="card-body">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="card-title">Users</h4>
      <button class="float-right add btn mb-3 btn-gradient-primary font-weight-bold todo-list-add-btn"
        (click)="createUser()" id="add-task">Add</button>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th class="font-weight-bold">Name</th>
              <th class="font-weight-bold">Email</th>
              <th class="font-weight-bold">Profile</th>
              <th class="font-weight-bold">Bio</th>
              <th class="font-weight-bold">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of userlist.data |paginate: {
              itemsPerPage: PageSize,
              currentPage: PageIndex,
              totalItems: totalRecords
              };
          let i= index;">
              <td>{{element.name}}</td>
              <td>{{element.email}}</td>
              <td class="py-1">
                <img *ngIf="element.profileImage" src="{{element.profileImage}}" width="30px" />
                <img *ngIf="!element.profileImage" src="assets/images/NullImage.png" width="30px" />
            </td>
              <td>{{element.bioData}}</td>

              <td class="">
                <button class="btn btn-outline-primary btn-sm py-2" (click)="editUser(element.userId)">
                  <i class="fa-solid fa-pen-to-square" title="Edit"></i></button>
                <span class="ml-2"></span>
                <button class="btn btn-outline-danger btn-sm py-2" (click)="deleteUser(element.userId)"><i
                    class="fa-solid fa-trash" title="Delete"></i></button>
              </td>
            </tr>

            <!-- <tr>
                  <td>2</td>
                  <td>2015/04/01</td>
                  <td>Doe</td>
                  <td>Brazil</td>
             
                  <td>
                    <label class="badge badge-danger">De Active</label>
                  </td>
                  <td>
                     <button class="btn btn-outline-primary btn-sm py-2 "><i class="fa-solid fa-pen-to-square"></i></button>
                    <span class="ml-2"></span>
                     <button class="btn btn-outline-danger  btn-sm py-2 "><i class="fa-solid fa-trash"></i></button>
                  </td>
                </tr> -->
          </tbody>
        </table>
        <div class="d-flex float-left mt-4 text-small">
          Showing {{startdata}} to {{enddata}} of {{totalRecords}} Records
        </div>
        <div class="d-flex float-right mt-3">

          <div class="mt-2">
            Items per Page:
            <select (change)="onTableSizeChange($event)">
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
          </div>
          <div>
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>