import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {
  vesrion: any;
  versionId: any;
  constructor(private appService: AppService, private _fb: FormBuilder) { }

  appVersion = this._fb.group({
    appName: [''],
    androidAppVersion: [''],
    playStoreUrl: [''],
    iosAppVersion: [''],
    appStoreUrl: [''],
    playStoreStatus: [true],
    appStoreStatus: [true],
    updateMessage: [''],
  });

  ngOnInit() {
    this.appService.appVersionById().subscribe((result: any) => {
      this.vesrion = result;
      this.versionId = 2;
      this.appVersion = new FormGroup({
        id: new FormControl(this.versionId),
        appName: new FormControl(result['appName']),
        androidAppVersion: new FormControl(result['androidAppVersion']),
        playStoreUrl: new FormControl(result['playStoreUrl']),
        iosAppVersion: new FormControl(result['iosAppVersion']),
        appStoreUrl: new FormControl(result['appStoreUrl']),
        playStoreStatus: new FormControl(result['playStoreStatus']),
        appStoreStatus: new FormControl(result['appStoreStatus']),
        updateMessage: new FormControl(result['updateMessage']),
      });
    });
  }

  udpateVersion() {
    this.appVersion.value.id = this.versionId;
    if (this.appVersion.value.id != null) {
      this.appService.appUpdateversion(this.appVersion.value).subscribe((res: any) => {
        if (res.status == 'success') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            confirmButtonText: 'OK',
            timer: 5000
          }).then((res) => {
            if (res.isConfirmed) {
            }
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.message,
            confirmButtonText: 'OK',
            timer: 5000
          }).then((res) => {
            if (res.isConfirmed) {
            }
          })
        }
      });
    }
  }

}
