<div class="page-header">
  <h3 class="page-title"> Sub Category Quote Create </h3>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a (click)="backToSubCategoryQuoteList()">Sub Category Qoute List</a></li>
      <li class="breadcrumb-item active" aria-current="page">Sub Category Quote Create</li>
    </ol>
  </nav>
</div>
<div class="row">

  <div class="col-md-12 grid-margin ">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title"> {{ editMode ? "Edit" : "Add" }} Sub Category Quote</h4>
        <p class="card-description"> </p>
        <form class="forms-sample" [formGroup]="subCategoryQuoteCreate" (ngSubmit)="onSubCategoryQuoteCreate()"
          novalidate>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Title <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Title"
                  formControlName="title">
                <div class="mt-1" *ngIf="subCategoryQuoteCreate.controls['title'].invalid && isValid">
                  <div class="text-small text-danger">
                    Please enter name.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6  ">
              <div class="w-75">
                <label>Image <span class="text-danger">*</span></label>
                <input type="file" class="dropify" formControlName="imageFile" (change)="onImageSubmit($event)" />
              </div>
            </div>
            <div class="col-6">
              <div class="w-75">
                <label class="mb-2" *ngIf="previousImg">Previous Image</label>
                <br>
                <img *ngIf="previousImg" src="{{previousImg}}" formControlName="imageFile" width="180px"
                  height="180px" />
              </div>
            </div>
            <div class="col-6"><br>
              <div class="w-75">
                <label>Languages <span class="text-danger">*</span></label><br>
                <select (change)="GetlanguageLists($event)" formControlName="language" class="form-control">
                  <option *ngFor="let data of Data" [value]="data.id">{{data.laguageName}}</option>
                </select>
                <div class="mt-1" *ngIf="subCategoryQuoteCreate.controls['language'].invalid && isValid">
                  <div class="text-small text-danger">
                    Languages is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <div class="form-group">
                <label>Status</label>
                <p>
                  <mat-slide-toggle formControlName="isActive"> Active </mat-slide-toggle>
                </p>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button type="submit" class="btn btn-gradient-primary mr-2">{{ editMode ? "Update" : "Submit" }}</button>
            <button class="btn btn-light" (click)="backToSubCategoryQuoteList()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>