<div class="page-header">
    <h3 class="page-title">Template Master</h3>

    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="#">Users</a></li>
              <li class="breadcrumb-item active" aria-current="page">Data table</li> -->
        </ol>
    </nav>
</div>
<div class="card">
    <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
            <h4 class="card-title">Template Master</h4>
            <button class="float-right add btn mb-3 btn-gradient-primary font-weight-bold todo-list-add-btn"
                (click)="createTemplate()" id="add-task">Add</button>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Order #</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Template Position</th>
                            <th>Name Position</th>
                            <th>Template Pattern</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td class="py-1">
                                <img src="../../../assets/images/faces-clipart/pic-1.png" alt="image" />
                            </td>
                            <td>Happy</td>
                            <td>Center</td>
                            <td>Center</td>
                            <td>Round</td>
                            <td>
                                <label class="badge badge-success">Active</label>
                            </td>

                            <td class="">
                                <button class="btn btn-outline-primary btn-sm py-2 "><i
                                        class="fa-solid fa-pen-to-square"></i></button>
                                <span class="ml-2"></span>
                                <button class="btn btn-outline-danger  btn-sm py-2 "><i
                                        class="fa-solid fa-trash"></i></button>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td class="py-1">
                                <img src="../../../assets/images/faces-clipart/pic-1.png" alt="image" />
                            </td>
                            <td>Happy</td>
                            <td>Center</td>
                            <td>Center</td>
                            <td>Round</td>
                            <td>
                                <label class="badge badge-danger">De Active</label>
                            </td>

                            <td class="">
                                <button class="btn btn-outline-primary btn-sm py-2 "><i
                                        class="fa-solid fa-pen-to-square"></i></button>
                                <span class="ml-2"></span>
                                <button class="btn btn-outline-danger  btn-sm py-2 "><i
                                        class="fa-solid fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>