

        <div class="page-header">
            <h3 class="page-title"> User</h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Forms</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Form elements</li>
                </ol>
            </nav>
        </div>
        <div class="row">
        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Default form</h4>
                    <p class="card-description"> Basic form layout </p>
                    <form class="forms-sample">
                        <div class="d-flex">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Name</label>
                                <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Username">
                            </div>
                            <div class="form-group ms-2">
                                <label for="exampleInputEmail1">Email</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Email">
                            </div>
                        </div>
                        
                        <div class="d-flex">

                            <div class="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <input type="password" class="form-control" id="exampleInputPassword1"
                                    placeholder="Password">
                            </div>
                            <div class="form-group ms-2" >
                                <label for="exampleInputConfirmPassword1">Confirm Password</label>
                                <input type="password" class="form-control" id="exampleInputConfirmPassword1"
                                    placeholder="Password">
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Phone Number</label>
                                <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Phone Number">
                            </div>
                            <div class="form-group ms-2">
                                <label for="exampleInputUsername1">Role</label>
                                <select class="js-example-basic-single form-select form-select-lg" >
                                  <option value="admin">Admin</option>
                                  <option value="super admin">Super Admin</option>
                                  
                                </select>
                              </div>

                        </div>
                
                        <button type="submit" class="btn btn-gradient-primary mr-2">Submit</button>
                        <button class="btn btn-light">Cancel</button>
                    </form>
                </div>
  

