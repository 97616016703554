<div class="page-header">
    <h3 class="page-title"> Category Creation </h3>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a (click)="backToCategoryList()">Category List</a></li>
            <li class="breadcrumb-item active" aria-current="page">Category Create</li>
        </ol>
    </nav>
</div>
<div class="row">
    <div class="col-md-12 grid-margin ">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">{{ editMode ? "Edit" : "Add" }} Category</h4>
                <p class="card-description"> </p>
                <form class="forms-sample" [formGroup]="addCategory" (ngSubmit)="onCategoryCreate()" novalidate>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Name"
                                    formControlName="CategoryName" (keypress)="Input($event)">
                                <div class="mt-1" *ngIf="addCategory.controls['CategoryName'].invalid && isValid">
                                    <div class="text-small text-danger">
                                        Category Name is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="exampleInputPassword1">Status</label>
                                <p>
                                    <mat-slide-toggle formControlName="isActive"> Active </mat-slide-toggle>
                                </p>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-gradient-primary mr-2"> {{ editMode ? "Update" : "Submit"
                        }}</button>
                    <button class="btn btn-light" (click)="backToCategoryList()">Cancel</button>
                </form>
            </div>
        </div>
    </div>

</div>