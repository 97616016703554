<div class="page-header">
    <h3 class="page-title"> Profile </h3>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a >Users List</a></li> -->
            <li class="breadcrumb-item active" aria-current="page">Profile</li>
        </ol>
    </nav>
</div>
<div class="row">

    <div class="col-md-12 grid-margin ">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Profile</h4>
                <p class="card-description"> </p>
                <form class="forms-sample" [formGroup]="userProfile" (submit)="onUserProfileUpdate()" novalidate>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Name</label>
                                <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Name"
                                    formControlName="name">

                                <div class="mt-1">
                                    <mat-error *ngIf="isValid && error('name','required')">Please enter name.
                                    </mat-error>

                                </div>

                            </div>

                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email </label>
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Email"
                                    formControlName="email" pattern="^\S*[@]\S*[.]\S*$" readonly>
                                <div class="mt-1">
                                    <mat-error *ngIf="isValid && error('email','required')">Please enter email.
                                    </mat-error>
                                    <mat-error *ngIf="error('email','pattern')">Please enter valid email.</mat-error>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="exampleInputPassword1">Phone Number</label>
                                <input type="text" class="form-control" id="exampleInputPassword1"
                                    placeholder="Phone Number" formControlName="phoneNumber" maxlength="10">
                                <div class="mt-1">
                                    <mat-error *ngIf="isValid && error('phoneNumber','required')">Please enter phone
                                        number.</mat-error>
                                    <mat-error *ngIf=" error('phoneNumber','pattern')">Please enter valid phone number.
                                    </mat-error>
                                </div>

                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <input type="password" class="form-control" id="exampleInputPassword1"
                                    placeholder="Ex:- John@123" formControlName="password" readonly>
                                <div class="mt-1">
                                    <mat-error *ngIf="isValid && error('password','required')">Please enter password.
                                    </mat-error>
                                    <mat-error *ngIf="error('password','pattern')">Please enter valid password.
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-6">
                            <div class="form-group">
                                <label class="exampleInputPassword1">Role</label>
                                <select class="form-control" formControlName="role" readonly>
                                    <option>User</option>
                                    <option>Admin</option>
                                    <option>Super Admin</option>
                                </select>
                                <div class="mt-1">
                                    <mat-error *ngIf="isValid && error('role','required')">Please select user role.
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </div>


                    <button type="submit" class="btn btn-gradient-primary mr-2">Submit</button>
                    <!-- <button class="btn btn-light" (click)="backToUserList()">Cancel</button> -->
                </form>
            </div>
        </div>
    </div>

</div>