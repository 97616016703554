import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teplate-master-list',
  templateUrl: './teplate-master-list.component.html',
  styleUrls: ['./teplate-master-list.component.css']
})
export class TeplateMasterListComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  createTemplate() {
      this.router.navigate(['dashboard/template-create']);
  }

}
