<div class="page-header">
  <h3 class="page-title"> Category</h3>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <!-- <li class="breadcrumb-item"><a href="#">Users</a></li>
            <li class="breadcrumb-item active" aria-current="page">Data table</li> -->
    </ol>
  </nav>
</div>
<div class="card">
  <div class="card-body">
    <div class="d-flex align-items-center justify-content-between">
      <h4 class="card-title font-weight-bold">Category</h4>
      <button class="float-right add btn mb-3 btn-gradient-primary font-weight-bold todo-list-add-btn"
        (click)="createCategory()" id="add-task">Add</button>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <!-- <th>Order #</th> -->
              <th class="font-weight-bold">Name</th>
              <!-- <th>Status</th> -->
              <th class="font-weight-bold">Actions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of categorylist |paginate: {
                itemsPerPage: PageSize,
                currentPage: PageIndex,
                totalItems: totalrecords
                };
            let i= index;">
              <!-- <td>1</td> -->
              <td>{{element.categoryName}}</td>
              <!-- <td>
                <label class="badge badge-success">Active</label>
              </td> -->

              <td class="">
                <!-- route="dashboard/category/{{element.categoryID}}" -->
                <button class="btn btn-outline-primary btn-sm py-2" (click)="editCategory(element.categoryID)">
                  <i class="fa-solid fa-pen-to-square" title="Edit"></i></button>
                <span class="ml-2"></span>
                <button class="btn btn-outline-danger  btn-sm py-2" (click)="deleteRow(element.categoryID)"><i
                    class="fa-solid fa-trash" title="Delete"></i></button>
              </td>
              <td>
                <button class="btn btn-gradient-primary btn-sm py-2" (click)="openSubCategory(element)">
                  Sub Category
                </button>
              </td>
            </tr>
            <!-- <tr>
              <td>2</td>
              <td>Happy</td>

              <td>
                <label class="badge badge-danger">De Active</label>
              </td>
      
              <td>
                <button class="btn btn-outline-primary btn-sm py-2 "><i
                    class="fa-solid fa-pen-to-square"></i></button>
                <span class="ml-2"></span>
                <button class="btn btn-outline-danger  btn-sm py-2 "><i class="fa-solid fa-trash"></i></button>
              </td>
            </tr>
            -->
          </tbody>
        </table>
        <div class="d-flex float-left mt-4 text-small">
          Showing {{startdata}} to {{enddata}} of {{totalrecords}} Records
        </div>
        <div class="d-flex float-right mt-3">

          <div class="mt-2">
            Items per Page:
            <select (change)="onTableSizeChange($event)">
              <option *ngFor="let size of pageSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
          </div>
          <div>
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
            </pagination-controls>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>