<div class="page-header">
    <h3 class="page-title">Report</h3>

    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="#">Users</a></li>
              <li class="breadcrumb-item active" aria-current="page">Data table</li> -->
        </ol>
    </nav>
</div>
<div class="card">
    <div class="card-body">
        <h4 class="card-title" (click)="ReportDatas()">Quote Report</h4>

        <div class="row">
            <div class="col-12">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Quote Image</th>
                            <th>UserName</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>View count</th>
                            <th>Like count</th>
                            <th>Download count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let report of Reportdata">
                            <td>
                                <img src="{{report.image}}" />
                                <img *ngIf="!report.image" src="assets/images/NullImage.png" width="30px" />
                            </td>
                            <td>{{report.userName}}</td>
                            <td>{{report.category}}</td>
                            <td>{{report.subCategory}}</td>
                            <td>{{report.viewCount}}</td>
                            <td>{{report.likeCount}}</td>
                            <td>{{report.downloadCount}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<tr *ngFor="let element of Reportdata|paginate: {
    itemsPerPage: PageSize,
    currentPage: PageIndex,
    totalItems: totalrecords};
    let i= index;">
</tr>
<div class="d-flex float-left mt-4 text-small">
    Showing {{startdata}} to {{enddata}} of {{totalrecords}} Records
</div>
<div class="d-flex float-right mt-3">
    <div class="mt-2">
        Items per Page:
        <select (change)="onTableSizeChange($event)">
            <option *ngFor="let size of pageSizes" [value]="size">
                {{ size }}
            </option>
        </select>
    </div>
    <div>
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
    </div>
</div>