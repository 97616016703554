import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-sub-category-list',
  templateUrl: './sub-category-list.component.html',
  styleUrls: ['./sub-category-list.component.css']
})
export class SubCategoryListComponent implements OnInit {

  public token: any = localStorage.getItem('token');
  public tokenConvert = JSON.parse(this.token);
  public userId: any = this.tokenConvert.userid;

  displayedColumns: any[] = ['id', 'name', 'imageName', 'action'];


  PageIndex: number = 1;
  count: number = 0;
  PageSize: number = 7;
  pageSizes = [7, 15, 25, 50, 100];
  totalrecords: any = 0;
  numitems: any;
  totalPages: any;
  currentpage: any;
  startdata: any = 0;
  enddata: any = 0;
  endtabledata: any;

  subcategorylist: any = [];
  subcategorybyId: any;
  CategoryId: any;
  subCategoryImageUrl: any;
  categoryName: any;
  result: any;

  constructor(private router: Router, private _activatedRoute: ActivatedRoute, private categoryservice: CategoryService) {
    this.subCategoryImageUrl = categoryservice.ImageUrl + "SubCategory/";
  }

  ngOnInit(): void {

    this.CategoryId = this._activatedRoute.snapshot.params['id'];
    this.categoryservice.getCategorywiseSubcategoryList(this.CategoryId, this.PageIndex, this.PageSize).subscribe((result) => {
      this.subcategorylist = result;
      this.totalrecords = (this.subcategorylist[0].totalRecords);
      localStorage.setItem('CategoryId', JSON.stringify(this.CategoryId));

      this.numitems = this.totalrecords - 1;

      var startindex = 0;
      this.totalPages = Math.floor(this.numitems / this.PageSize);
      this.currentpage = Math.ceil(startindex / this.PageSize);

      this.startdata = ((this.PageIndex - 1) * this.PageSize) + 1;
      this.endtabledata = ((this.startdata + this.PageSize) - 1);
      this.enddata = (this.endtabledata) > (this.totalrecords) ? (this.totalrecords) : (this.endtabledata);

    });

    this.categoryservice.getCategorybyId(this._activatedRoute.snapshot.params['id']).subscribe((result: any) => {
      this.result = result;
      this.categoryName = this.result.categoryName;
    });
  }

  createSubCategory() {
    this.router.navigate(['dashboard/sub-category-create'])
  }

  deleteRow(item: any) {

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.categoryservice.deleteSubCategory(item).subscribe((result: any) => {
          this.ngOnInit();
          if (result.status == "success") {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Deleted!',
              text: result.message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (result.status == "error") {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Cancelled',
              text: result.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    });
  }

  editSubCategory(subCategoryID: any) {

    this.categoryservice.getSubCategorybyId(subCategoryID).subscribe((result: any) => {
      this.subcategorybyId = result;
    });
    this.router.navigate(['dashboard/sub-category-edit', subCategoryID]);
  }
  navigateCategory() {
    this.router.navigate(['/dashboard/category']);
  }

  // openSubCategoryQuote(obj:any){
  //   this.router.navigate(['dashboard/sub-category', obj.categoryID]);
  //   localStorage.setItem('categoryID', JSON.stringify(obj.categoryID));
  // }
  openSubCategoryQuote(obj: any) {

    // this.router.navigate(['dashboard/sub-category']);
    this.router.navigate(['dashboard/sub-category-quote-list'], obj.subcategoryID);
    localStorage.setItem('SubCategoryID', obj.subcategoryID);
    localStorage.setItem('subcategoryid', JSON.stringify(obj.subcategoryID))
  }

  onTableDataChange(event: any) {
    this.PageIndex = event;
    this.ngOnInit();
  }
  onTableSizeChange(event: any): void {
    this.PageSize = Number(event.target.value);
    this.PageIndex = 1;
    this.ngOnInit();
  }
  // handlePageSizeChange(event: any): void {
  //   this.PageSize = event.target.value;
  //   this.PageIndex = 1;
  //   this.ngOnInit();
  // }

  // handlePageChange(event:any) {
  //   this.PageIndex = event;
  //   this.ngOnInit();
  // }

}
