import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AppService } from '../app.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login: FormGroup;
  hide = true;
  errorMsgHide = false;
  isValid = false;
  value: any;
  public errMsg = "Please Enter Valid Email and Password";
  msg: any;
  role: any;
  roleId: any;
  public errorMsg = false;

  constructor(private router: Router, private _fb: FormBuilder, private _service: AppService) {
    this.login = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  public error = (control: string, error: string) => {
    return this.login.controls[control].hasError(error);
  }

  ngOnInit(): void {
    if (localStorage.getItem('accessToken') != null) {
      this.router.navigate(['dashboard/category']);
    }
  }

  Input(event: any) {
    if (event.target.value != null) {
      this.isValid = false;
    }
    this.value = event.target.value;
  }

  submit() {
    this.isValid = true;
    if (this.login.invalid) {
      return;
    }
    else {
      this._service.postLoginList(this.login.value).subscribe((result: any) => {
        if (result.status == 'success') {
          const token = (<any>result).token;
          const userId = (<any>result).userid;
          const refreshToken = (<any>result).refreshToken;
          this.role = JSON.parse(window.atob(token.split('.')[1]))["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
          this.roleId = (<any>result).role;
          localStorage.setItem('token', JSON.stringify(result));
          localStorage.setItem("accessToken", token);
          localStorage.setItem("UserId", userId);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("role", this.role);
          localStorage.setItem("roleId", this.roleId);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully Login',
            showConfirmButton: false,
            timer: 1500
          });
          this.router.navigate(['dashboard/category']);
        }
        else if (result.status == 'error') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Check User Credential',
            showConfirmButton: false,
            timer: 1500
          });
        }
      },
        error => {
          if (error.status == 401) {
            this.errorMsgHide = true;
            this.errorMsg = true;
            this.msg = this.errMsg;
          }
        }
      );
    }
  }

  onKeypressEvent(event: any) {
    if (event.key == "Enter") {
      this.submit();
    }
  }
}
