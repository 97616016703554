<div class="container-scroller">
  <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <a class="navbar-brand brand-logo"><img src="assets/web_logo.svg" alt="logo" /></a>
      <a class="navbar-brand brand-logo-mini"><img src="assets/web_icon.svg" alt="logo" /></a>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-stretch">
      <button class="navbar-toggler align-self-center" type="button" data-toggle="minimize">
        <span class="margin">
          <mat-icon class="fs-5" (click)="drawer.toggle()">menu</mat-icon>
        </span>
      </button>
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-profile dropdown">
          <a class="nav-link dropdown-toggle" id="profileDropdown" href="#" data-toggle="dropdown"
            aria-expanded="false">
            <div class="nav-profile-img">
              <img src="assets/images/faces/face1.jpg" alt="image">
              <span class="availability-status online"></span>
            </div>
            <div class="nav-profile-text">
              <p class="mb-1 text-black">{{email}}</p>
            </div>
          </a>
          <div class="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
            <a class="dropdown-item" (click)="editUser()">
              <i class=" mr-2 text-success">
                <mat-icon class="fs-6">account_circle</mat-icon>
              </i> Profile </a>
            <a class="dropdown-item" routerLink="change-password">
              <i class=" mr-2 text-danger">
                <mat-icon class="fs-5">vpn_key</mat-icon>
              </i> Change Password </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="onLogOut()">
              <i class="mr-2 text-primary">
                <mat-icon class="fs-5">exit_to_app</mat-icon>
              </i> Signout </a>
          </div>
        </li>
        <li class="nav-item d-none d-lg-block full-screen-link">
          <a class="nav-link">
            <i class="" id="fullscreen-button">
              <mat-icon class="fs-5">fullscreen</mat-icon>
            </i>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
            <i class="">
              <mat-icon class="">notifications_none</mat-icon>
            </i>
            <span class="count-symbol bg-danger"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
            aria-labelledby="notificationDropdown">
            <h6 class="p-3 mb-0">Notifications</h6>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-success">
                  <i class=""></i>
                </div>
              </div>signup
              <p class="text-gray ellipsis mb-0"> Just a reminder that you have an event today </p>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-warning">
                  <i class=""></i>
                </div>
              </div>
              <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                <h6 class="preview-subject font-weight-normal mb-1">Settings</h6>
                <p class="text-gray ellipsis mb-0"> Update dashboard </p>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item preview-item">
              <div class="preview-thumbnail">
                <div class="preview-icon bg-info">
                  <i class=""></i>
                </div>
              </div>
              <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                <h6 class="preview-subject font-weight-normal mb-1">Launch Admin</h6>
                <p class="text-gray ellipsis mb-0"> New admin wow! </p>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <h6 class="p-3 mb-0 text-center">See all notifications</h6>
          </div>
        </li>

      </ul>
    </div>
  </nav>
  <div class="page-body-wrapper">
    <mat-drawer-container autosize>
      <mat-drawer #drawer mode="side">
        <nav class="sidebar sidebar-offcanvas">
          <ul class="nav">
            <li class="nav-item nav-profile">
              <a class="nav-link" (click)="editUser()">
                <div class="nav-profile-image">
                  <img src="../../../assets/images/faces/face1.jpg" alt="profile">
                  <span class="login-status online"></span>
                </div>
                <div class="nav-profile-text d-flex flex-column">
                  <span class="font-weight-bold mb-2">{{email}}</span>
                </div>
                <i class="text-success nav-profile-badge"></i>
              </a>
            </li>
            <div *ngFor="let menu of menuList">
              <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" href="" [routerLink]="[menu.moduleURL]">
                  <span class="menu-title">{{menu.moduleName}}</span>
                  <i class="menu-icon">
                    <mat-icon [innerHTML]="menu.icon"></mat-icon>
                  </i>
                </a>
              </li>
            </div>
          </ul>
        </nav>
      </mat-drawer>
    </mat-drawer-container>
    <div class="main-panel">
      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>