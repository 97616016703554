import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
  changePassword: any;
  isValid = false;
  public token: any = localStorage.getItem('token');
  public tokenConvert = JSON.parse(this.token);
  email = this.tokenConvert.userName;
  submitted: boolean = false;
  hideOldPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  constructor(private _fb: FormBuilder, private _service: AppService) {

    this.changePassword = this._fb.group({
      email: [this.email],
      oldpassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validators: ConfirmPasswordValidator('newPassword', 'confirmPassword')
      });
  }

  public error = (control: string, error: string) => {
    return this.changePassword.controls[control].hasError(error);
  }

  ngOnInit() {
    
  }

  onSubmit() {
    this.submitted = true;
    this.isValid = true;
    if (this.changePassword.invalid) {
      return;
    }
    else {
      this._service.postChangePassword(this.changePassword.value).subscribe((res: any) => {
        if (res.status == 'success') {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            confirmButtonText: 'OK',
            timer: 5000
          }).then((res) => {
            if (res.isConfirmed) {
              location.reload();
            }
          })
        }
        else if (res.status == 'error') {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.message,
            confirmButtonText: 'OK',
            timer: 5000
          })
        }

      })
    }
  }
}

export function ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName];
    let matchingControl = formGroup.controls[matchingControlName]
    if (
      matchingControl.errors &&
      !matchingControl.errors['confirmPasswordValidator']
    ) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmPasswordValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

