<div class="page-header">
    <h3 class="page-title">Sub Category quotes</h3>

    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a (click)="navigateCategory()">Category</a></li>
            <li class="breadcrumb-item" aria-current="page"><a (click)="backToSubCategoryList()">Sub Category</a></li>
            <li class="breadcrumb-item active" aria-current="page">Quotes</li>
        </ol>
    </nav>
</div>
<div class="card">
    <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
            <h4 class="card-title">{{subCategoryName}}</h4>
            <!-- <select class="margin btn mb-3 btn-gradient-info md-3" placeholder="Language" readonly>
                <option value="Super Admin">Super Admin</option>
                <option value="Admin">Admin</option>
                <option value="User">User</option>
              </select> -->
            <div class="dropdown mb-3">
                <select class="dropbtn margin btn-gradient-primary" (change)="selectLanguage($event)">Dropdown
                    <option value="" class="btn-gradient-primary" selected>All</option>
                    <option value="English" class="btn-gradient-primary">English</option>
                    <option value="Hindi" class="btn-gradient-primary">Hindi</option>
                </select>
            </div>
            <button class="float-right add btn mb-3 btn-gradient-primary font-weight-bold todo-list-add-btn md-3"
                (click)="createSubCategoryQuote()" id="add-task">Add</button>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <!-- <th>Order #</th> -->
                            <th class="font-weight-bold">Name</th>
                            <th class="font-weight-bold">Image</th>

                            <!-- <th>Sub Category</th> -->
                            <!-- <th>Status</th> -->
                            <th class="font-weight-bold">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of LanguageList|paginate: {
                            itemsPerPage: PageSize,
                            currentPage: PageIndex,
                            totalItems: totalrecord
                            };
                        let i= index;">
                            <!-- <td>1</td> -->
                            <td>{{element.title}}</td>
                            <td class="py-1">
                                <img *ngIf="element.quoteImageUrl" src="{{element.quoteImageUrl}}" width="30px" />
                                <img *ngIf="!element.quoteImageUrl" src="assets/images/NullImage.png" width="30px" />

                            </td>

                            <!-- <td>Happy</td> -->
                            <!-- <td>
                                <label class="badge badge-success">Active</label>
                            </td> -->

                            <td class="">
                                <!-- (click)="editSubCategory(element.subcategoryID)" -->
                                <button class="btn btn-outline-primary btn-sm py-2"
                                    (click)="editQuote(element.quoteId)"><i
                                        class="fa-solid fa-pen-to-square"></i></button>
                                <span class="ml-2"></span>
                                <button class="btn btn-outline-danger  btn-sm py-2"
                                    (click)="deleteRow(element.quoteId)"><i class="fa-solid fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-4" *ngIf="!LanguageList.length">
                    <p>No record Found.</p>
                </div>
                <div class="d-flex float-left mt-4 text-small">

                    Showing {{startdata}} to {{enddata}} of {{totalrecord}} Records
                </div>
                <div class="d-flex float-right mt-3">
                    <div class="mt-2">
                        Items per Page:
                        <select (change)="onTableSizeChange($event)">
                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                {{ size }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <pagination-controls previousLabel="Prev" nextLabel="Next"
                            (pageChange)="onTableDataChange($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>