import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

  displayedColumns: any[] = ['categoryName', 'status', 'action'];
  categorylist: any = [];
  categorybyId: any;
  categorywiseSubCategoylist: any = [];

  PageIndex: number = 1;
  PageSize: number = 7;
  pageSizes = [7, 15, 25, 50, 100];
  totalrecords: any = 0;
  numitems: any;
  totalPages: any = 0;
  currentpage: any;
  startdata: any = 0;
  enddata: any = 0;
  endtabledata: any;

  constructor(private router: Router, private categoryservice: CategoryService) { }

  ngOnInit(): void {

    this.categoryservice.getCategoryList(this.PageIndex, this.PageSize).subscribe((result: any) => {
      this.categorylist = result;
      this.totalrecords = ((this.categorylist[0].totalRecords) || 0);

      this.numitems = this.totalrecords - 1;

      var startindex = 0;
      this.totalPages = Math.floor(this.numitems / this.PageSize);
      this.currentpage = Math.ceil(startindex / this.PageSize);

      this.startdata = ((this.PageIndex - 1) * this.PageSize) + 1;
      this.endtabledata = (this.startdata + this.PageSize) - 1;
      this.enddata = (this.endtabledata) > (this.totalrecords) ? (this.totalrecords) : (this.endtabledata);

    });
  }

  deleteRow(item: any) {

    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.categoryservice.deleteCategory(item).subscribe((result: any) => {
          this.ngOnInit();
          if (result.status == "success") {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Deleted!',
              text:result.message,
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (result.status == "error") {
            Swal.fire({
              title: 'Cancelled',
              text: result.message,
              icon: 'error'
            });
          }

        });
      }
    });
  }

  createCategory() {
    this.router.navigate(['dashboard/category-add']);
  }
  editCategory(categoryID: any) {
    this.categoryservice.getCategorybyId(categoryID).subscribe((result: any) => {
      this.categorybyId = result;
    });
    this.router.navigate(['dashboard/category-edit', categoryID]);
  }

  openSubCategory(obj: any) {
    localStorage.setItem('categoryID', obj.categoryID);
    this.router.navigate(['dashboard/sub-category', obj.categoryID]);
  }

  onTableDataChange(event: any) {
    this.PageIndex = event;
    this.ngOnInit();
  }

  onTableSizeChange(event: any): void {
    this.PageSize = Number(event.target.value);
    this.ngOnInit();
  }
}
