<div class="card w-50 ">
    <div class="card-body ">
        <h4 class="card-title ">Excel<span class="text-danger">*</span></h4>
        <input type="file" [ngModel]="selectExcel" (change)="onExcelFileChange($event)">
        <button type="submit" mat-raised-button (click)="onImportExcel()" *ngIf=show
            class="btn btn-gradient-primary ml-5">{{isLoadingExcel ? 'Uploading...':'Upload'}}
            <span class="spinner-grow spinner-grow-sm text-light" *ngIf="isLoadingExcel"></span>
        </button>
    </div>
</div>
<br><br>
<div class="card w-50 ">
    <div class="card-body ">
        <h4 class="card-title ">Zip<span class="text-danger">*</span></h4>
        <input type="file" [ngModel]="selectZip" (change)="onZipFileChanges($event)" class="ms-3">
        <button type="submit" mat-raised-button (click)="onImportZip()" *ngIf=shown
            class="btn btn-gradient-primary ml-5">
            {{isLoadingZip ? 'Uploading...':'Upload'}}
            <span class="spinner-grow spinner-grow-sm text-light" *ngIf="isLoadingZip"></span>
        </button>
    </div>
</div>