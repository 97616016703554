import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { ToastrModule } from 'ngx-toastr';
import { CategoryAddComponent } from './category/category-add/category-add.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SubCategoryAddComponent } from './category/sub-category-add/sub-category-add.component';
import { SubCategoryQuoteAddComponent } from './category/sub-category-quote-add/sub-category-quote-add.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { TeplateMasterComponent } from './template/teplate-master/teplate-master.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CategoryModule } from './category/category.module';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { UsersModule } from './users/users.module';
import { JwtInterceptor } from './jwt.interceptor';
import { AuthGuardGuard } from './guard/auth-guard.guard';
import { ImportquoteComponent } from './importquote/importquote.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { ReportsModule } from './reports/reports.module';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { PermissionComponent } from './permission/permission.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { VersionComponent } from './version/version.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoginComponent,
    SignupComponent,
    UserCreateComponent,
    CategoryAddComponent,
    SubCategoryAddComponent,
    SubCategoryQuoteAddComponent,
    ProfileComponent,
    ChangePasswordComponent,
    TeplateMasterComponent,
    ImportquoteComponent,
    PagenotfoundComponent,
    PermissionComponent,
    VersionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatInputModule,
    ToastrModule.forRoot(),
    MatSlideToggleModule,
    HttpClientModule,
    CommonModule,
    CategoryModule,
    UsersModule,
    DashboardModule,
    ReportsModule,
    MatSidenavModule,
    MatProgressSpinnerModule
  ],
  providers: [AuthGuardGuard, { provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent],

})
export class AppModule { }
function appRoutes(appRoutes: any): any[] | import("@angular/core").Type<any> | import("@angular/core").ModuleWithProviders<{}> {
  throw new Error('Function not implemented.');
}

