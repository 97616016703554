<div class="page-header">
    <h3 class="page-title"> Change Password </h3>
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
        </ol>
    </nav>
</div>
<div class="content-wrapper d-flex align-items-center auth">
    <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left p-5">
                <div class="brand-logo">
                    <h6 class="font-weight-light page-title">Change Password</h6>
                </div>
                <form class="pt-3" [formGroup]="changePassword">
                    <div class="form-group d-none" hidden>
                        <input type="email" class="form-control form-control-lg" formControlName="email"
                            id="exampleInputEmail1" placeholder="Email or Phone">
                        <div class="mt-1">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <input type="password" class="form-control form-control-lg" formControlName="oldpassword"
                                id="password" id="exampleInputPassword1" placeholder="Old Password" matInput
                                [type]="hideOldPassword ? 'password' : 'text'">
                            <span class="input-group-text" (click)="hideOldPassword = !hideOldPassword">
                                <i *ngIf="!hideOldPassword" class="far fa-eye" id="togglePassword"
                                    style="cursor: pointer"></i>
                                <i *ngIf="hideOldPassword" class="fa fa-eye-slash" id="togglePassword"
                                    style="cursor: pointer"></i>
                            </span>
                        </div>
                        <div class="mt-1" *ngIf="submitted && changePassword.get('oldpassword').errors">
                            <div class="text-small text-danger">
                                Please enter old password.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <input type="password" class="form-control form-control-lg" formControlName="newPassword"
                                id="exampleInputPassword1" placeholder="New Password" matInput
                                [type]="hideNewPassword ? 'password' : 'text'">
                            <span class="input-group-text" (click)="hideNewPassword = !hideNewPassword">
                                <i *ngIf="!hideNewPassword" class="far fa-eye" id="togglePassword"
                                    style="cursor: pointer"></i>
                                <i *ngIf="hideNewPassword" class="fa fa-eye-slash" id="togglePassword"
                                    style="cursor: pointer"></i>
                            </span>
                        </div>
                        <div class="mt-1" *ngIf="submitted && changePassword.get('newPassword').errors">
                            <div class="text-small text-danger">
                                Please enter new password.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <input type="password" class="form-control form-control-lg"
                                formControlName="confirmPassword" id="exampleInputPassword1"
                                placeholder="Confirm Password" matInput
                                [type]="hideConfirmPassword ? 'password' : 'text'">
                            <span class="input-group-text" (click)="hideConfirmPassword = !hideConfirmPassword">
                                <i *ngIf="!hideConfirmPassword" class="far fa-eye" id="togglePassword"
                                    style="cursor: pointer"></i>
                                <i *ngIf="hideConfirmPassword" class="fa fa-eye-slash" id="togglePassword"
                                    style="cursor: pointer"></i>
                            </span>
                        </div>
                        <div class="mt-1">
                            <div *ngIf="submitted && changePassword.get('confirmPassword').errors"
                                class="text-small text-danger">
                                <div *ngIf="changePassword.get('confirmPassword').errors.required">Confirm Password
                                    is
                                    required</div>

                                <div *ngIf="changePassword.get('confirmPassword').errors.confirmPasswordValidator">
                                    Passsword and
                                    Confirm Password didn't match. </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <a class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                            (click)="onSubmit()">Change</a>
                    </div>
                    <div class="my-2 d-flex justify-content-between align-items-center">
                    </div>
                    <div class="mb-2">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>