<div class="page-header">
  <h3 class="page-title"> Sub Category Create </h3>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a (click)="backToSubCategoryList()">Sub Category List</a></li>
      <li class="breadcrumb-item active" aria-current="page">Sub Category Create</li>
    </ol>
  </nav>
</div>
<div class="row">

  <div class="col-md-12 grid-margin ">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ editMode ? "Edit" :"Add" }} Sub Category</h4>
        <p class="card-description"> </p>
        <form class="forms-sample" [formGroup]="addSubCategory" (ngSubmit)="onSubCategoryCreate()" novalidate>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="exampleInputUsername1">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Name"
                  formControlName="SubCategoryName" (keypress)="Input($event)">
                <div class="mt-1" *ngIf="addSubCategory.controls['SubCategoryName'].invalid && isValid">
                  <div class="text-small text-danger">
                    Sub Category Name is required.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="w-75">
                <label>Image <span class="text-danger">*</span></label>

                <input #file type="file" accept="image/*" class="dropify" formControlName="Image"
                  (change)="onImageSubmit($event)" />
              </div>
            </div>
            <div class="col-6  ">
              <div class="w-75">
                <label class="mb-2" *ngIf="previousImg">Previous Image</label>
                <br>
                <img *ngIf="previousImg" src="{{previousImg}}" formControlName="Image" width="180px" height="180px" />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <div class="form-group">
                <label>Status</label>
                <p>
                  <mat-slide-toggle formControlName="IsActive"> Active </mat-slide-toggle>
                </p>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <button type="submit" class="btn btn-gradient-primary mr-2">{{ editMode ? "Update" : "Submit" }}</button>
            <button class="btn btn-light" (click)="backToSubCategoryList()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>