import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivateGuardGuard } from './activate-guard.guard';
import { CategoryAddComponent } from './category/category-add/category-add.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { SubCategoryAddComponent } from './category/sub-category-add/sub-category-add.component';
import { SubCategoryListComponent } from './category/sub-category-list/sub-category-list.component';
import { SubCategoryQuoteAddComponent } from './category/sub-category-quote-add/sub-category-quote-add.component';
import { SubCategoryQuoteListComponent } from './category/sub-category-quote-list/sub-category-quote-list.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AuthGuardGuard } from './guard/auth-guard.guard';
import { ImportquoteComponent } from './importquote/importquote.component';
import { LoginComponent } from './login/login.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { ViewsReportComponent } from './reports/views-report/views-report.component';
import { SignupComponent } from './signup/signup.component';
import { TeplateMasterListComponent } from './template/teplate-master-list/teplate-master-list.component';
import { TeplateMasterComponent } from './template/teplate-master/teplate-master.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { RoleGuard } from './role.guard';
import { PermissionComponent } from './permission/permission.component';
import { VersionComponent } from './version/version.component';

const routes: Routes = [
  { path: "", component: LoginComponent, pathMatch: "full" },
  { path: "login", redirectTo: "", pathMatch: "full" },
  {
    path: "dashboard", component: NavBarComponent, canActivate: [AuthGuardGuard], children: [
      { path: "create", component: SignupComponent, canActivate: [ActivateGuardGuard, RoleGuard] },
      { path: "user-list", component: UserListComponent, canActivate: [ActivateGuardGuard, RoleGuard] },
      { path: "user-create", component: UserCreateComponent, canActivate: [ActivateGuardGuard, RoleGuard] },
      { path: "user-edit/:id", component: UserCreateComponent, canActivate: [ActivateGuardGuard] },
      { path: "user-edits/:id", component: UserCreateComponent, canActivate: [ActivateGuardGuard] },
      { path: "category", component: CategoryListComponent, canActivate: [ActivateGuardGuard] },
      { path: "category-add", component: CategoryAddComponent, canActivate: [ActivateGuardGuard] },
      { path: "category-edit/:id", component: CategoryAddComponent, canActivate: [ActivateGuardGuard] },
      { path: "sub-category", component: SubCategoryListComponent, canActivate: [ActivateGuardGuard] },
      { path: "sub-category/:id", component: SubCategoryListComponent, canActivate: [ActivateGuardGuard] },
      { path: "sub-category-create", component: SubCategoryAddComponent, canActivate: [ActivateGuardGuard] },
      { path: "sub-category-edit/:id", component: SubCategoryAddComponent, canActivate: [ActivateGuardGuard] },
      { path: "sub-category-quote-list", component: SubCategoryQuoteListComponent, canActivate: [ActivateGuardGuard] },
      { path: "sub-category-quote-create", component: SubCategoryQuoteAddComponent, canActivate: [ActivateGuardGuard] },
      { path: "sub-category-quote-edit/:id", component: SubCategoryQuoteAddComponent, canActivate: [ActivateGuardGuard] },
      { path: "profile", component: ProfileComponent, canActivate: [ActivateGuardGuard] },
      { path: "dashboard", component: DashboardComponent, canActivate: [ActivateGuardGuard] },
      { path: "change-password", component: ChangePasswordComponent },
      { path: "template-master", component: TeplateMasterListComponent },
      { path: "template-create", component: TeplateMasterComponent },
      { path: "report", component: ViewsReportComponent },
      { path: "importquote", component: ImportquoteComponent, canActivate: [ActivateGuardGuard] },
      { path: "version", component: VersionComponent, canActivate: [ActivateGuardGuard]  },
      { path: "permission", component: PermissionComponent },
      { path: "**", redirectTo: 'permission', pathMatch: "full" },
    ]
  },
  {
    path: "**", children: [{
      path: "**", component: PagenotfoundComponent,
    }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
