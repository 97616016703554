import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public accounturl = environment.url + 'api/UserMaster/Admin/';

  GetUserList = 'GetUserList';
  DeleteUser = 'DeleteUser';
  GetUserById = 'GetUserById';
  AddUser = 'Register';
  GetModulewiseRolePermissionList = 'GetModulewiseRolePermissionList';
  constructor(private _http: HttpClient) { }

  addUser(data: any) {
    const addUser = this.accounturl + this.AddUser
    return this._http.post(addUser, data);
  }

  getUserList(PageIndex: any, PageSize: any) {
    return this._http.get(`${this.accounturl + this.GetUserList + "?PageIndex="}${PageIndex}&PageSize=${PageSize}`);
  }

  getUserById(UserID: any) {
    return this._http.get(`${this.accounturl + this.GetUserById + "?userid="}${UserID}`);
  }

  deleteUser(id: any) {
    return this._http.delete(`${this.accounturl + this.DeleteUser + "?userId="}${id}`);
  }

  GetModulewiseRolePermission(roleId: any) {
    return this._http.get(`${this.accounturl + this.GetModulewiseRolePermissionList + "?roleId="}${roleId}`);
  }
}
